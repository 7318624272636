import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="___________">
      <div className="headline">
        <h1 className="text-primary m-0">___________</h1>
      </div>
    
    </DocumentLayout>
  )
}
